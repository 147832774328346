var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
define(["require", "exports", "preact", "preact/hooks", "./styles.css"], function (require, exports, preact_1, hooks_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    function VideoJsComponent(props) {
        var _this = this;
        var videoRef = (0, hooks_1.useRef)();
        var _a = (0, hooks_1.useState)(false), playerDataReady = _a[0], setPlayerDataReady = _a[1];
        var _b = (0, hooks_1.useState)({
            playerId: '',
            embedId: '',
            accountId: '',
            videoId: ''
        }), playerInfo = _b[0], setPlayerInfo = _b[1];
        (0, hooks_1.useEffect)(function () {
            setPlayerInfo(props.playerInfo);
            setPlayerDataReady(true);
        }, [props.playerInfo]);
        (0, hooks_1.useEffect)(function () {
            var videoElement = videoRef.current;
            if (playerDataReady) {
                loadPlayer(videoElement);
            }
        }, [videoRef, playerDataReady]);
        var loadPlayer = function (videoElement) {
            var refParentNode = videoElement.parentNode;
            var script = document.createElement('script');
            script.onload = function () { return __awaiter(_this, void 0, void 0, function () {
                var fetchAndInitialize;
                return __generator(this, function (_a) {
                    fetchAndInitialize = window === null || window === void 0 ? void 0 : window.fetchAndInitialize;
                    if (window === null || window === void 0 ? void 0 : window.fetchAndInitialize) {
                        fetchAndInitialize(playerInfo.accountId, playerInfo.playerId + '_' + playerInfo.embedId);
                    }
                    return [2 /*return*/];
                });
            }); };
            script.onerror = function () {
                console.log('Player script could not be downloaded.');
            };
            script.async = true;
            script.src = '/bc_helper.js';
            refParentNode.appendChild(script);
        };
        // @ts-ignore
        return (0, preact_1.h)("div", { className: "video-js-component-container" },
            (0, preact_1.h)("video-js", { ref: videoRef, className: "video-js-component", "data-account": playerInfo.accountId, "data-player": playerInfo.playerId, "data-embed": playerInfo.embedId, "data-video-id": playerInfo.videoId, controls: true, preload: "none" }));
    }
    exports.default = VideoJsComponent;
});
